import { useState } from 'react'
import { useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import * as yup from 'yup'
import { Field, Form, Formik } from 'formik'
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  LinearProgress,
  Typography,
} from '@mui/material'
import { useAddUser, useGetUserList } from 'hooks/customers'
import { CustomAutocompleteInput } from 'components/common'

interface IValues {
  value: {
    id: number
  }
  isPm: string
}

const AddNewUser: React.FC = (): JSX.Element => {
  const { t } = useTranslation([
    'systemBusinessUnits',
    'projectManageUser',
    'buttons',
    'validation',
  ])
  const { addUser } = useAddUser()
  const { usersList } = useGetUserList()
  const params = useParams()
  const [checked, setChecked] = useState(false)
  const initialValues = {} as IValues
  const validationSchema = yup.object({
    value: yup.object().required(t('user.required', { ns: 'validation' })),
  })
  return (
    <Box sx={{ marginTop: '1.5rem' }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, actions) => {
          values?.value?.id !== undefined &&
            addUser({ userId: values?.value?.id, isPm: checked, id: params.id })
          actions.setSubmitting(false)
        }}
      >
        {({ isSubmitting, errors, touched }) => {
          return (
            <Form>
              <Box
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <Field
                  component={CustomAutocompleteInput}
                  optionsData={
                    usersList.map((user) => {
                      return {
                        name: `${user.firstName} ${user.lastName}`,
                        id: user.id,
                      }
                    }) || []
                  }
                  name="value"
                  label={t('form.label1', { ns: 'projectManageUser' })}
                  disableClearable={true}
                />{' '}
                <Field
                  as={FormControlLabel}
                  type="checkbox"
                  name="checked"
                  control={<Checkbox checked={checked} />}
                  label={t('form.label2', { ns: 'projectManageUser' })}
                  onChange={() => setChecked(!checked)}
                />
                {errors.value && touched && (
                  <Typography color="error" paddingY={1} id="feedback">
                    {errors.value}
                  </Typography>
                )}
              </Box>
              <Button disabled={isSubmitting} type="submit">
                {t('save', { ns: 'buttons' })}
              </Button>
              {isSubmitting && <LinearProgress />}
            </Form>
          )
        }}
      </Formik>
    </Box>
  )
}
export default AddNewUser

import { useEffect, useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { AxiosResponse } from 'axios'
import { useTranslation } from 'react-i18next'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { NOTIFICATION, useNotifications } from 'components/common'

export interface IHolidays {
  id: number
  name: string
  date: string
}
const deleteHolidayFromDB = async (id: number | undefined) => {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.HOLIDAYS_DELETE,
    {},
    { id }
  )
  return data
}
async function getHolidays(): Promise<IHolidays[]> {
  const { data } = await apiRequest(APIRoutes.HOLIDAYS_GETALL)
  return data
}

export const useHolidays = () => {
  const [holiday, setHoliday] = useState<IHolidays | null>(null)
  const { t } = useTranslation('handleMessage')
  const queryClient = useQueryClient()
  const { modalName, setNotification, handleCloseModal } = useNotifications()

  const fallback: IHolidays[] = []

  const { data: holidaysList = fallback } = useQuery(
    queryKeys.holidays,
    getHolidays
  )

  const { mutate: deleteHoliday } = useMutation(deleteHolidayFromDB, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('holiday')} ${t('delete')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      void queryClient.invalidateQueries([queryKeys.holidays])
      void queryClient.invalidateQueries([queryKeys.calendar])
      void queryClient.invalidateQueries([queryKeys.user])
    },
  })

  useEffect(() => {
    if (!modalName) {
      setHoliday(null)
    }
  }, [modalName])
  return { holidaysList, deleteHoliday, setHoliday, holiday }
}

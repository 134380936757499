import { IAllocations, IProjects } from 'hooks/types'
import { useAuth } from 'hooks/useAuth'
import { useCalendarContext } from 'pages/Calendar/context'
import { format, isAfter, isBefore } from 'date-fns'
import { useNotifications } from 'components/common'
import _ from 'lodash'

export const useWorkTypes = () => {
  const { user } = useAuth()
  const { currentCalendarDate } = useNotifications()
  const { types } = useCalendarContext()

  const projects =
    user?.projects?.filter((project: IProjects) => {
      const activeAlloc = user?.allocations?.find(
        (alloc: IAllocations) =>
          alloc.ProjectUser.projectId === project.id &&
          format(new Date(currentCalendarDate), 'yyyy/MM') === alloc.date
      )
      return activeAlloc !== undefined
    }) || []
  const isWorkTypeHoliday = (workTypeId) => {
    return user?.system?.workTypes?.find(
      (workType) => workType?.id === workTypeId
    )?.isHoliday
  }
  const workTypesOnlyWithoutNeedApproval = () => {
    return types.filter((type) => !type.needApproval)
  }
  const workTypesOnlyWithNeedApproval = () => {
    return types.filter((type) => type.needApproval)
  }
  const currentAgreements = user?.UserAgreements?.filter((agreement) =>
    isBefore(new Date(agreement.startDate), new Date()) && agreement.endDate
      ? isAfter(new Date(agreement.endDate), new Date())
      : true
  )

  const currentAgreementsIds = currentAgreements?.map(
    (agreement) => agreement.Agreement.id
  )

  const workTypesOnlyWithUserAgreements = () => {
    return types.filter(
      (type) =>
        type.agreements.find((agreement) =>
          currentAgreementsIds?.includes(agreement.id)
        ) || type.agreements.length === 0
    )
  }
  const workTypesOnlyWithUserProjects = () => {
    const projects =
      user?.projects?.filter((project: IProjects) => {
        const activeAlloc = user?.allocations?.find(
          (alloc: IAllocations) =>
            alloc.ProjectUser.projectId === project.id &&
            format(new Date(currentCalendarDate), 'yyyy/MM') === alloc.date
        )
        return activeAlloc !== undefined
      }) || []
    return types.filter(
      (type) =>
        projects.find((project) => project.id === type.projectId) ||
        type.projectId === 0
    )
  }

  const isNeedApproval = (page) => {
    return page === 'VacationRequests'
      ? workTypesOnlyWithNeedApproval()
      : workTypesOnlyWithoutNeedApproval()
  }
  const getUserWorkTypes = (page) => {
    const commonIds = _.intersection(
      isNeedApproval(page).map((worktype) => worktype.id),
      workTypesOnlyWithUserAgreements()?.map((worktype) => worktype.id),
      workTypesOnlyWithUserProjects().map((worktype) => worktype.id)
    )
    return types.filter((type) => commonIds.includes(type.id))
  }
  const workTypesOnlyForSelectedProject = (selectedProject, page) => {
    return getUserWorkTypes(page).filter(
      (workType) =>
        workType.projectId === Number(selectedProject) ||
        workType.projectId === 0
    )
  }
  const isWorkTypeAssignableToProject = (workTypeId) => {
    const worktype = types.find((worktype) => worktype.id === workTypeId)
    const isWorkTypeWithProject =
      !worktype?.isAssignableToProject && !worktype?.isHoliday
    return !isWorkTypeWithProject
  }

  const getProject = () => {
    const savedProject = user?.projects?.find(
      (project) => Number(localStorage.getItem('projectId')) === project.id
    )
    const isProjectForCurrentMonth = !!projects.find(
      (project) => project.id === savedProject?.id
    )
    return {
      id: isProjectForCurrentMonth ? savedProject?.id || null : projects[0]?.id,
      name: isProjectForCurrentMonth
        ? savedProject?.name || ''
        : projects[0]?.name,
    }
  }
  const getWorkType = () => {
    const firstWorkTypeWithProject = workTypesOnlyForSelectedProject(
      getProject().id,
      'VacationRequests'
    ).filter(
      (worktype) => worktype.isAssignableToProject || worktype.isHoliday
    )?.[0]
    return {
      id: firstWorkTypeWithProject?.id || null,
      name: firstWorkTypeWithProject?.name || '',
    }
  }

  return {
    workTypesOnlyForSelectedProject,
    getUserWorkTypes,
    isWorkTypeHoliday,
    isWorkTypeAssignableToProject,
    projects,
    currentAgreements,
    getWorkType,
    getProject,
  }
}

import { format } from 'date-fns'
import { useAuth } from 'hooks/useAuth'

interface IUserHolidays {
  usedPlanned: number
  used: number
  maxHolidays: number
}

export const usedDashboard = () => {
  const { user } = useAuth()

  const response: IUserHolidays = {
    usedPlanned: 0,
    used: 0,
    maxHolidays: 0,
  }
  const getUserHolidays = () => {
    const currentYear = new Date().getFullYear()

    const holidaysForCurrentYear = user?.UserHolidays?.filter(
      (userHoliday) => userHoliday.year === currentYear
    )
    if (user?.UserHolidays && holidaysForCurrentYear?.length) {
      const reducedUserHolidays: IUserHolidays = holidaysForCurrentYear
        ?.filter((holiday) => format(new Date(holiday.year), 'yyyy'))
        .reduce((acc, curr) => {
          acc.usedPlanned += curr.usedPlannedDays
          acc.used += curr.usedDays
          acc.maxHolidays += curr.days
          return acc
        }, response)
      return reducedUserHolidays
    } else {
      return response
    }
  }

  const userHolidays = getUserHolidays()
  return userHolidays
}

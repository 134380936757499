import { useAuth } from 'hooks/useAuth'
import { VacationRequestFormik } from 'pages/VacationRequest/components'
import { IInitialValues } from 'pages/VacationRequest/components/VacationRequestFormik'
import { useVacationRequests } from 'pages/VacationRequest/hooks'
import { useWorkTypes } from 'utils'

const AddVacationApplication = (): JSX.Element => {
  const { user } = useAuth()
  const { getWorkType, getProject } = useWorkTypes()

  const initialValues: IInitialValues = {
    valueStartDate: new Date(),
    valueEndDate: new Date(),
    comment: '',
    vacationType: {
      id: 0 as number,
      name: '',
    },
    workType: getWorkType(),
    project: getProject(),
  }
  const { addVacationRequest } = useVacationRequests(user?.id)
  return (
    <VacationRequestFormik
      initialValues={initialValues}
      onSubmit={addVacationRequest}
    />
  )
}

export default AddVacationApplication

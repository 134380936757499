import { useTranslation } from 'react-i18next'
import { IFields } from 'views/LayoutUI/components/types'

export const useDataFields = () => {
  const { t } = useTranslation('vacationRequests')

  const vacationDates: IFields[] = [
    {
      id: '5',
      label: t('modal.add.startDate'),
      name: 'valueStartDate',
    },
    {
      id: '6',
      label: t('modal.add.endDate'),
      name: 'valueEndDate',
    },
  ]
  return vacationDates
}

export enum queryKeys {
  activityLog = 'activityLog',
  agreements = 'agreements',
  allInventories = 'allInventories',
  allUsersList = 'allUsersList',
  allWorktypes = 'allWorktypes',
  applicationsList = 'applicationsList',
  businessUnits = 'businessUnits',
  businessUnitsList = 'businessUnitsList',
  calendar = 'calendar',
  customer = 'customer',
  customers = 'customers',
  customersLimit = 'customersLimit',
  excel = 'excel',
  customerAllocations = 'customerAllocation',
  excelFinal = 'excelFinal',
  filteredPkupsList = 'filteredPkupsList',
  freeInventories = 'freeInventories',
  fteCalculation = 'fteCalculation',
  groups = 'groups',
  helpfulUsers = 'helpfulUsers',
  history = 'history',
  holidays = 'holidays',
  holidaysCategories = 'holidaysCategories',
  inventories = 'inventoriesList',
  location = 'location',
  locationsList = 'locationsList',
  me = 'me',
  usersAllocations = 'usersAllocations',
  notes = 'notes',
  notification = 'notification',
  permissions = 'permissions',
  pkupDetails = 'pkupDetails',
  pkupReportsList = 'pkupReportsList',
  pkupWorks = 'pkupWorks',
  pkupsToReview = 'pkupsToReview',
  preview = 'preview',
  projectDetailsCalendar = 'projectDetailsCalendar',
  projectHistory = 'projectHistory',
  projects = 'projects',
  projectsForPkupList = 'projectsForPkupList',
  projectsLimit = 'projectsLimit',
  reportCustomers = 'reportCustomers',
  reportProjects = 'reportProjects',
  revisionsList = 'revisionsList',
  skills = 'skills',
  tags = 'tags',
  tagsPerPage = 'tagsPerPage',
  templates = 'templates',
  timeFrames = 'timeFrames',
  timesheet = 'timesheet',
  user = 'user',
  userAgreements = 'userAgreements',
  userDetails = 'userDetails',
  userById = 'userById',
  userByIdBio = 'userByIdBio',
  userByIdEdu = 'userByIdEdu',
  userByIdProjects = 'userByIdProjects',
  userByIdProfile = 'userByIdProfile',
  userOne = 'userOne',
  users = 'users',
  usersAll = 'usersAll',
  vacationApproversList = 'vacationApproversList',
  vacationRequestsList = 'vacationRequestsList',
  workdaysInMonth = 'workdaysInMonth',
  workdaysToReview = 'workdaysToReview',
  worksList = 'worksList',
}

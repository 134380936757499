import {
  Dispatch,
  createContext,
  useContext,
  useState,
  SetStateAction,
  ReactNode,
} from 'react'

export interface AllocationData {
  customDate: Date | null
  setCustomDate: Dispatch<SetStateAction<Date | null>>
  initialValues: {
    startValue: Date | null
  }
}

const defaultApi: AllocationData = {
  customDate: null,
  setCustomDate: () => null,
  initialValues: {
    startValue: null,
  },
}
export type AllocationContextValue = typeof defaultApi
export const AllocationContext = createContext<AllocationData>(defaultApi)

export const AllocationProvider: React.FC<ReactNode> = ({ children }) => {
  const [customDate, setCustomDate] = useState<Date | null>(null)
  const initialValues = {
    startValue: customDate,
  }

  const contextValue = {
    customDate,
    setCustomDate,
    initialValues,
  }

  return (
    <AllocationContext.Provider value={contextValue}>
      {children}
    </AllocationContext.Provider>
  )
}

export function useAllocationsContext() {
  return useContext(AllocationContext)
}

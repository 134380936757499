import {
  Dispatch,
  createContext,
  useEffect,
  useContext,
  useCallback,
  useState,
  SetStateAction,
  FC,
  ReactNode,
} from 'react'
import { format } from 'date-fns'
import { Workday } from 'pages/Calendar/context/types'
import { ModalType, OpenType } from 'hooks/types'

export enum NOTIFICATION {
  warning = 'warning',
  error = 'error',
  success = 'success',
}

interface Notification {
  message?: null | string
  variant?: NOTIFICATION
  permissionAlert?: null | string
}

export interface NotificationData {
  notifications: Notification
  setNotification: (notification: Notification) => void
  clearNotification: (id: number) => void
  openNotification: boolean
  setOpenNotification: Dispatch<SetStateAction<boolean>>
  openModal: OpenType
  setOpenModal: Dispatch<SetStateAction<boolean>>
  modalName: ModalType
  setModalName: (name: ModalType) => void
  handleCloseModal: () => void
  handleOpenModal: (name: ModalType) => void
  userDetailsId: number | null
  setUserDetailsId: (id: number) => void
  openBar: OpenType
  setOpenBar: Dispatch<SetStateAction<boolean>>
  barName: ModalType
  setBarName: (name: ModalType) => void
  handleCloseBar: () => void
  handleOpenBar: (name: ModalType) => void
  currentCalendarDate: string
  setCurrentCalendarDate: Dispatch<SetStateAction<string>>
  projectCalendarDate: string
  setProjectCalendarDate: Dispatch<SetStateAction<string>>
  selectedUserWorkDays: Workday[]
  setSelectedUserWorkDays: Dispatch<SetStateAction<Workday[]>>
  isOpenPopup: boolean
  setIsOpenPopup: Dispatch<SetStateAction<boolean>>
  openSideBar: boolean
  setOpenSideBar: Dispatch<SetStateAction<boolean>>
  isPlaying: boolean
  setIsPlaying: Dispatch<SetStateAction<boolean>>
}

const defaultApi: NotificationData = {
  notifications: {
    message: '',
    variant: NOTIFICATION.warning,
    permissionAlert: '',
  },
  setNotification: () => null,
  clearNotification: () => null,
  openNotification: false,
  setOpenNotification: () => null,
  openModal: false,
  setOpenModal: () => null,
  modalName: '',
  setModalName: () => null,
  handleCloseModal: () => null,
  handleOpenModal: () => null,
  userDetailsId: null,
  setUserDetailsId: () => null,
  openBar: false,
  setOpenBar: () => null,
  barName: '',
  setBarName: () => null,
  handleCloseBar: () => null,
  handleOpenBar: () => null,
  currentCalendarDate: '',
  setCurrentCalendarDate: () => null,
  projectCalendarDate: '',
  setProjectCalendarDate: () => null,
  selectedUserWorkDays: [],
  setSelectedUserWorkDays: () => null,
  isOpenPopup: false,
  setIsOpenPopup: () => null,
  openSideBar: false,
  setOpenSideBar: () => null,
  isPlaying: false,
  setIsPlaying: () => null,
}

export type NotificationsContextValue = typeof defaultApi

/**
 * Create Context
 */

export const NotificationsContext = createContext<NotificationData>(defaultApi)
/**
 * Custom Notifications Provider
 */
export const NotificationsProvider: FC<ReactNode> = ({ children }) => {
  // Notifications queue is managed in local useState
  const [notifications, setNotifications] = useState<Notification>(
    defaultApi.notifications
  )
  const [openNotification, setOpenNotification] = useState<boolean>(false)

  const [openModal, setOpenModal] = useState<OpenType>(false)
  const [modalName, setModalName] = useState<ModalType>('')
  const [userDetailsId, setUserDetailsId] = useState<number | null>(null)

  const [openBar, setOpenBar] = useState<OpenType>(false)
  const [barName, setBarName] = useState<ModalType>('')
  const [isOpenPopup, setIsOpenPopup] = useState(true)
  const [openSideBar, setOpenSideBar] = useState(true)
  const [isPlaying, setIsPlaying] = useState(true)

  const [selectedUserWorkDays, setSelectedUserWorkDays] = useState<Workday[]>(
    []
  )

  const [currentCalendarDate, setCurrentCalendarDate] = useState<string>(
    format(new Date(), 'yyyy-MM')
  )
  const [projectCalendarDate, setProjectCalendarDate] = useState<string>(
    format(new Date(), 'yyyy-MM')
  )

  useEffect(() => {
    if (notifications?.message) {
      setOpenNotification(true)
    }
  }, [notifications])

  useEffect(() => {
    if (modalName) {
      setOpenModal(true)
    }
  }, [modalName])

  useEffect(() => {
    if (barName) {
      setOpenBar(true)
    }
  }, [barName])

  const handleCloseModal = () => {
    setIsOpenPopup(true)
    setModalName('')
    setOpenModal(false)
  }
  const handleOpenModal = (name: ModalType) => {
    setModalName(name)
  }

  const handleCloseBar = () => {
    setBarName('')
    setOpenBar(false)
  }
  const handleOpenBar = (name: ModalType) => {
    setBarName(name)
  }
  //Method to push a new notification
  const setNotification = useCallback(
    (notification: Notification) => {
      setNotifications(notification)
    },
    [notifications, setNotifications]
  )

  // Method to clear notifications
  const clearNotification = useCallback(() => {
    setNotifications(defaultApi.notifications)
  }, [notifications, setNotifications])

  const contextValue = {
    notifications,
    setNotification,
    clearNotification,
    openNotification,
    setOpenNotification,
    openModal,
    setOpenModal,
    modalName,
    setModalName,
    handleCloseModal,
    handleOpenModal,
    userDetailsId,
    setUserDetailsId,
    openBar,
    setOpenBar,
    barName,
    setBarName,
    handleCloseBar,
    handleOpenBar,
    currentCalendarDate,
    setCurrentCalendarDate,
    projectCalendarDate,
    setProjectCalendarDate,
    selectedUserWorkDays,
    setSelectedUserWorkDays,
    isOpenPopup,
    setIsOpenPopup,
    openSideBar,
    setOpenSideBar,
    isPlaying,
    setIsPlaying,
  }

  return (
    <NotificationsContext.Provider value={contextValue}>
      {children}
    </NotificationsContext.Provider>
  )
}

export function useNotifications() {
  return useContext(NotificationsContext)
}

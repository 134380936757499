import { Dispatch, SetStateAction } from 'react'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns'
import { isUserPermitted, permissionList } from 'services'
import {
  Box,
  Button,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from '@mui/material'
import {
  AllocationBadge,
  DownloadButton,
  EmptyArrayCard,
  TableComponent,
  useNotifications,
} from 'components/common'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import CommentIcon from '@mui/icons-material/Comment'
import { useData, useVacationRequests } from 'pages/VacationRequest/hooks'
import {
  IVacationRequest,
  StatusEnum,
} from 'pages/VacationRequest/hooks/useVacationRequests'
import { useApprovers } from 'pages/VacationRequest/hooks/useApprovers'
import { useAuth } from 'hooks/useAuth'
import { useTablePagedFunction } from 'hooks/table'
import { orderIcon } from 'utils'
import palette from 'theme/palette'

interface YourVacationRequestProps {
  setSelectedRequest: Dispatch<SetStateAction<IVacationRequest | undefined>>
}
const YourVacationRequest: React.FC<YourVacationRequestProps> = ({
  setSelectedRequest,
}): JSX.Element => {
  const { t } = useTranslation(['vacationRequests', 'buttons'])
  const { handleOpenModal } = useNotifications()
  const { user } = useAuth()
  const { vacationRequestsList, changePagination, paginationUc } =
    useVacationRequests(user?.id)
  const { getStatusColor, ButtonStyle } = useData()
  const { sortBy, handleChangePage, paginationUC, handleChangeRowsPerPage } =
    useTablePagedFunction(changePagination)
  const vacationApproversList = useApprovers(user?.id)

  const columns = [
    <>
      <Button
        onClick={() => sortBy('id', 'ASC')}
        variant="text"
        sx={ButtonStyle}
      >
        #{orderIcon(paginationUc?.order, 'id')}
      </Button>
    </>,
    t('panel1.requestsTable.name'),
    t('panel1.requestsTable.vacationType'),
    <>
      <Button
        onClick={() => sortBy('startDate', 'ASC')}
        variant="text"
        sx={ButtonStyle}
      >
        {t('panel1.requestsTable.startDate')}{' '}
        {orderIcon(paginationUc?.order, 'startDate')}
      </Button>
    </>,
    <>
      <Button
        onClick={() => sortBy('endDate', 'ASC')}
        variant="text"
        sx={ButtonStyle}
      >
        {t('panel1.requestsTable.endDate')}
        {orderIcon(paginationUc?.order, 'endDate')}
      </Button>
    </>,
    t('panel1.requestsTable.days'),
    <>
      <Button
        onClick={() => sortBy('requestDate', 'ASC')}
        variant="text"
        sx={ButtonStyle}
      >
        {t('panel1.requestsTable.requestDate')}
        {orderIcon(paginationUc?.order, 'requestDate')}
      </Button>
    </>,
    t('panel1.requestsTable.comment'),
    t('panel1.requestsTable.status'),
    '',
  ]
  const rows = vacationRequestsList?.data?.map((vacation) => {
    return {
      id: vacation.id,
      workType: vacation.workTypeName,
      vacationType: vacation.vacationType || '--',
      startDate: format(new Date(vacation.startDate), 'yyyy-MM-dd'),
      endDate: format(new Date(vacation.endDate), 'yyyy-MM-dd'),
      days: vacation.days,
      date: format(new Date(vacation.requestDate), 'yyyy-MM-dd'),
      comment: vacation.comment ? (
        <Tooltip title={vacation.comment}>
          <IconButton color="info">
            <CommentIcon />
          </IconButton>
        </Tooltip>
      ) : (
        '--'
      ),
      status: (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            color: getStatusColor(vacation.status),
          }}
        >
          {vacation.status}
          {vacation.approverComment && (
            <Tooltip title={vacation.approverComment as string}>
              <IconButton color="info">
                <CommentIcon />
              </IconButton>
            </Tooltip>
          )}
        </Box>
      ),
      button:
        vacation.status === StatusEnum.pending ? (
          <>
            {isUserPermitted(user, [
              permissionList.VACATION_REQUESTS_UPDATE,
            ]) && (
              <Tooltip title={t('edit', { ns: 'buttons' }) as string}>
                <IconButton
                  onClick={() => {
                    setSelectedRequest(vacation), handleOpenModal('edit')
                  }}
                >
                  <EditIcon />
                </IconButton>
              </Tooltip>
            )}
            {isUserPermitted(user, [
              permissionList.VACATION_REQUESTS_DELETE,
            ]) && (
              <Tooltip title={t('delete', { ns: 'buttons' }) as string}>
                <IconButton
                  onClick={() => {
                    setSelectedRequest(vacation), handleOpenModal('delete')
                  }}
                >
                  <DeleteIcon color="error" />
                </IconButton>
              </Tooltip>
            )}
          </>
        ) : (
          vacation.status === StatusEnum.approved &&
          vacation.holidayCategoryId && (
            <DownloadButton
              setSelected={setSelectedRequest}
              vacation={vacation}
              isDisabled={!user?.locations[0]}
            />
          )
        ),
    }
  })
  const vacationCategoriesColumn = [
    t('panel1.holidaysTable.name'),
    t('panel1.holidaysTable.usage'),
    t('panel1.holidaysTable.year'),
  ]

  const vacationCategoriesRows = user?.UserHolidays?.sort(
    (h1, h2) => h1.year - h2.year
  ).map((vacationCategory) => {
    const vacationDays = [
      {
        hours: vacationCategory.days - vacationCategory.usedDays,
        tooltip: t('panel1.holidaysTable.tooltips.availableDays'),
        color: 'primary.main',
      },
      {
        hours: vacationCategory.usedDays,
        tooltip: t('panel1.holidaysTable.tooltips.usedDays'),
        color: 'primary.medium',
      },
      {
        hours: vacationCategory.days,
        tooltip: t('panel1.holidaysTable.tooltips.limitDays'),
        color: 'primary.light',
      },
    ]
    return {
      name: vacationCategory?.HolidaysCategory?.name,
      days: (
        <>
          {vacationCategory.days === vacationCategory.usedDays ? (
            t('panel1.holidaysTable.allDaysAreUsed')
          ) : (
            <AllocationBadge details={vacationDays} />
          )}
        </>
      ),
      year: vacationCategory.year,
    }
  }) as []

  return (
    <Box>
      <Paper sx={{ padding: '1rem', backgroundColor: palette.secondary.main }}>
        {isUserPermitted(user, [permissionList.VACATION_REQUESTS_CREATE]) && (
          <Button
            onClick={() => handleOpenModal('add')}
            sx={{ width: '200px' }}
            disabled={
              !user?.locations.length ||
              !user?.UserAgreements?.length ||
              !user?.businessUnits.length
            }
          >
            {t('panel1.button')}
          </Button>
        )}
        <Box
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          {!!vacationCategoriesRows.length && (
            <Box sx={{ maxWidth: '40rem', minWidth: '55%' }}>
              <TableComponent
                rows={vacationCategoriesRows}
                columns={vacationCategoriesColumn}
              />
            </Box>
          )}
          <Paper
            sx={{
              minWidth: '100px',
              width: !vacationCategoriesRows.length ? '100%' : '40%',
              marginTop: '1rem',
              padding: '0.5rem 1rem',
            }}
          >
            <Typography variant="h3">
              <b>{t('panel1.vacationApprovers')}:</b>
            </Typography>
            {vacationApproversList.length ? (
              vacationApproversList.map((user) => (
                <Typography variant="subtitle1" key={user.userId}>
                  {user.firstName} {user.lastName}
                </Typography>
              ))
            ) : (
              <EmptyArrayCard message={t('panel1.noApprovers')} />
            )}
          </Paper>
        </Box>
      </Paper>
      {isUserPermitted(user, [permissionList.VACATION_REQUESTS_READ]) &&
        !!vacationRequestsList?.data?.length && (
          <TableComponent
            rows={rows}
            columns={columns}
            total={vacationRequestsList?.total}
            pagination={true}
            changePagination={changePagination}
            handleChangePage={handleChangePage}
            paginationUC={paginationUC}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
          />
        )}
    </Box>
  )
}
export default YourVacationRequest

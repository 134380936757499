import React, { useState, useMemo, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import { format } from 'date-fns/esm'
import { Box, Tab, Container, Tooltip, Alert } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab'
import { useAuth } from 'hooks/useAuth'
import { HeaderTab } from 'views/LayoutUI/components'
import {
  GradientLinearProgress,
  ModalComponent,
  useNotifications,
} from 'components/common'
import { DefaultModal } from 'views/LayoutUI/modals'
import HouseboatOutlinedIcon from '@mui/icons-material/HouseboatOutlined'
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined'
import SupportOutlinedIcon from '@mui/icons-material/SupportOutlined'
import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined'
import GroupsIcon from '@mui/icons-material/Groups'
import { permissionList, isUserPermitted } from 'services'
import { ISelectingModal } from 'hooks/types'
import { useWorkTypes } from 'utils'

import { usedDashboard } from './utils'
import {
  Background,
  Cards,
  ProjectsWithoutAllocations,
  YourProjects,
} from './components'
import { StyledButton } from './index.style'
import { Details, UsersThatMayHelpYou } from './modals'

const Dashboard: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['dashboard', 'buttons'])
  const [value, setValue] = useState('1')
  const [businessUnitsId, setBusinessUnitsId] = useState<number | null>(null)
  const userHolidays = usedDashboard()
  const navigate = useNavigate()
  const { user } = useAuth()
  const countPercentage =
    userHolidays.maxHolidays === 0
      ? 0
      : (userHolidays.used / userHolidays.maxHolidays) * 100
  const { modalName, handleOpenModal } = useNotifications()
  const { currentAgreements } = useWorkTypes()
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setValue(newValue)
  }
  const usedHolidaysDays =
    (100 * userHolidays.used) / userHolidays.maxHolidays
      ? ((100 * userHolidays.used) / userHolidays.maxHolidays).toFixed()
      : 0
  const selectingModal = (): ISelectingModal => {
    switch (modalName) {
      case 'details':
        return {
          component: <Details id={businessUnitsId} />,
          title: `${t('cardsPanel.card4.title')}`,
        }
      case 'usersThatMayHelpYou':
        return {
          component: <UsersThatMayHelpYou />,
          title: `${t('modal.usersMayHelpYou.title')}`,
        }

      default:
        return { component: <DefaultModal />, title: 'Default modal' }
    }
  }

  const holidayCard = {
    title: `${t('cardsPanel.card1.title')} ${userHolidays.used}/${
      userHolidays.maxHolidays
    }`,
    subtitle: t('cardsPanel.card1.year'),
    subtitle2: (
      <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
        <span>{t('cardsPanel.card1.usedDays')}</span>
        <span>{usedHolidaysDays}%</span>
      </Box>
    ),
    component: (
      <GradientLinearProgress
        variant="determinate"
        value={countPercentage}
        barcolor="primary.light"
        progressvalue={countPercentage}
      />
    ),

    icon: (
      <HouseboatOutlinedIcon sx={{ fontSize: '3rem', color: 'primary.main' }} />
    ),
  }

  const agreementCard = {
    title: t('cardsPanel.card2.title'),
    subtitle:
      currentAgreements?.length === 0
        ? t('cardsPanel.card2.notAvailable')
        : currentAgreements?.length === 1
        ? t('cardsPanel.card2.currentAgreement')
        : t('cardsPanel.card2.currentAgreements'),
    subtitle2: (
      <>
        {currentAgreements?.map((agreement) => (
          <Tooltip
            title={`${format(new Date(agreement.startDate), 'yyyy-MM-dd')} - ${
              agreement.endDate
                ? format(new Date(agreement.endDate), 'yyyy-MM-dd')
                : t('cardsPanel.card2.contractIndefinite')
            }`}
            key={agreement.id}
            placement="right"
          >
            <Box sx={{ width: 'fit-content' }}>{agreement.Agreement.type}</Box>
          </Tooltip>
        ))}
      </>
    ),
    icon: (
      <AssignmentOutlinedIcon
        sx={{ fontSize: '3rem', color: 'primary.main' }}
      />
    ),
  }
  const helpingUsersCard = {
    title: t('cardsPanel.card3.title'),
    subtitle: t('cardsPanel.card3.help'),
    icon: (
      <SupportOutlinedIcon sx={{ fontSize: '3rem', color: 'primary.main' }} />
    ),
    button: (
      <StyledButton
        onClick={() => handleOpenModal('usersThatMayHelpYou')}
        size="small"
      >
        {t('cardsPanel.card3.button')}
      </StyledButton>
    ),
  }
  const businessUnitsCards = user?.businessUnits?.map((businessUnit) => ({
    title: businessUnit.name,
    subtitle: t('cardsPanel.card4.businessUnits'),
    icon: (
      <GroupsIcon
        sx={{ fontSize: '3rem', color: 'primary.contrastText', strokeWidth: 1 }}
      />
    ),
    button: (
      <>
        {isUserPermitted(user, [permissionList.BUSINESS_UNIT_READ]) && (
          <StyledButton
            btnwhite="true"
            onClick={() => {
              handleOpenModal('details')
              setBusinessUnitsId(businessUnit.id)
            }}
            size="small"
          >
            {t('details', { ns: 'buttons' })}
          </StyledButton>
        )}
      </>
    ),
    secondButton: (
      <>
        {isUserPermitted(user, [permissionList.BUSINESS_UNIT_LIST]) && (
          <StyledButton
            btncolor="true"
            onClick={() =>
              navigate(`/system/businessUnits/${businessUnit.id}/users`)
            }
            size="small"
          >
            {t('manage', { ns: 'buttons' })}
          </StyledButton>
        )}
      </>
    ),
    backgroundColor: 'primary.main',
  }))
  const workingCard = {
    title: `${t('cardsPanel.card5.workingOn')}...`,
    subtitle: (
      <>
        <Box>
          {user?.currentWork?.project?.name
            ? user.currentWork.project.name
            : t('cardsPanel.card5.notWorkingOn')}
        </Box>
        <Box>
          {user?.currentWork && (
            <>
              {t('cardsPanel.card5.from')}{' '}
              {format(
                Date.parse(user.currentWork.logDayFrom),
                'yyyy-MM-dd HH:mm'
              )}{' '}
              {t('cardsPanel.card5.to')}{' '}
              {user?.currentWork &&
                format(Date.parse(user.currentWork.logDayTo), 'HH:mm')}
            </>
          )}
        </Box>
      </>
    ),
    color: 'primary.main',
    icon: (
      <WorkOutlineOutlinedIcon
        sx={{ fontSize: '3rem', color: 'primary.main' }}
      />
    ),
  }
  const listOfCards = [
    { content: holidayCard, timeout: 1000 },
    { content: agreementCard, timeout: 1500 },
    { content: helpingUsersCard, timeout: 2000 },
    { content: workingCard, timeout: 2500 },
  ]

  const cardsArea = useMemo(
    () => (
      <Cards
        listOfCards={listOfCards}
        businessUnitsCards={businessUnitsCards}
      />
    ),
    []
  )
  const [alertVisible, setAlertVisibility] = useState(false)
  useEffect(() => {
    if (
      !user?.locations.length ||
      !user.UserAgreements?.length ||
      !user.businessUnits.length
    )
      setAlertVisibility(true)
  }, [user])
  return (
    <>
      <Background />
      <Container>
        <HeaderTab translate="dashboard" />
        {alertVisible && (
          <Alert
            variant="filled"
            severity="warning"
            onClose={() => setAlertVisibility(false)}
            sx={{ margin: '24px' }}
          >
            {`${[
              !user?.locations.length && t('alert.location'),
              !user?.UserAgreements?.length && t('alert.agreement'),
              !user?.businessUnits.length && t('alert.bu'),
            ]
              .filter(Boolean)
              .map((s, i) => {
                if (i === 0 && typeof s === 'string') {
                  return s.charAt(0).toUpperCase() + s.slice(1)
                } else {
                  return s
                }
              })
              .join(', ')}
           ${t('alert.notFound')}`}
          </Alert>
        )}
        {cardsArea}
        <TabContext value={value}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleChange} aria-label="lab API tabs example">
              <Tab label={t('tabPanels.panel1.title')} value="1" />
              <Tab label={t('tabPanels.panel2.title')} value="2" />
            </TabList>
          </Box>
          <TabPanel value="1">
            <YourProjects value="1" />
          </TabPanel>
          <TabPanel value="2">
            <ProjectsWithoutAllocations value="2" />
          </TabPanel>
        </TabContext>
        <ModalComponent
          component={selectingModal().component}
          title={selectingModal().title}
        />
      </Container>
    </>
  )
}

export default Dashboard

import { useState } from 'react'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Container,
  Button,
  Fade,
  IconButton,
  Tooltip,
  Typography,
} from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import { useGetAllHolidays } from 'hooks/holidays/useGetAllHolidays'
import { IButtonArr, ISelectingModal } from 'hooks/types'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { HeaderTab } from 'views/LayoutUI/components'
import {
  EmptyArrayCard,
  ModalComponent,
  ModalConfirm,
  TableComponent,
  useNotifications,
} from 'components/common'
import { DefaultModal } from 'views/LayoutUI/modals'
import { isUserPermitted, permissionList } from 'services'
import { useAuth } from 'hooks/useAuth'
import { timeoutAnimation } from 'utils'
import { DisabledIconButton } from 'components/common/DisabledIconButton/index.style'

import { AddCategory, EditCategory } from './modals'
import {
  IHolidaysCategory,
  useHolidaysCategories,
} from './useHolidaysCategories'

export interface IInitialValues {
  name?: string
}
const HolidaysCategories: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['systemHolidaysCategories', 'buttons'])
  const { user } = useAuth()
  const { handleOpenModal, modalName, setModalName } = useNotifications()
  const holidaysCategories = useGetAllHolidays()
  const { deleteCategory } = useHolidaysCategories()
  const [selectedCategory, setSelectedCategory] = useState<IHolidaysCategory>({
    id: 0,
    name: '',
  })
  const buttonsArr: IButtonArr[] = [
    {
      title: `${t('edit', { ns: 'buttons' })}`,
      path: null,
      icon: <EditIcon />,
      modal: 'edit',
    },
    {
      title: `${t('delete', { ns: 'buttons' })}`,
      path: null,
      icon: <DeleteIcon color="error" />,
      modal: 'delete',
    },
  ]
  const columns = ['#', `${t('table.cell2')}`, '']
  const rows: GridRowsProp = holidaysCategories.map((category) => {
    return {
      id: category.id,
      name: category.name,
      button: (
        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
          {buttonsArr.map((button: IButtonArr) => {
            const showButton = button.modal !== 'delete' || category.canDelete

            return isUserPermitted(user, [
              button.modal === 'edit'
                ? permissionList.HOLIDAY_CATEGORY_EDIT
                : permissionList.HOLIDAY_CATEGORY_DELETE,
            ]) && showButton ? (
              <Tooltip
                title={button.title as string}
                key={button.title}
                onClick={() => handleOpen(category, button.modal as string)}
              >
                <IconButton>{button.icon}</IconButton>
              </Tooltip>
            ) : (
              <Tooltip
                title={`${t('categoryIsAssignedToHoliday')}`}
                key={button.title}
              >
                <DisabledIconButton>{button.icon}</DisabledIconButton>
              </Tooltip>
            )
          })}
        </Box>
      ),
    }
  })
  const validationSchema = yup.object({
    name: yup
      .string()
      .required(`${t('name.required', { ns: 'validation' })}`)
      .min(3, `${t('name.minLength', { ns: 'validation' })}`),
  })
  const initialValuesAdd: IInitialValues = {
    name: '',
  }
  const initialValuesEdit: IInitialValues = {
    name: '' || selectedCategory?.name,
  }
  const deleteContent = (
    <>
      <Typography variant="subtitle2"> {t('modal.delete.text1')}</Typography>
      <Typography variant="subtitle1">
        {': ' + selectedCategory?.name}
      </Typography>
      ?
    </>
  )
  const selectingModal = (): ISelectingModal => {
    switch (modalName) {
      case 'addCategory':
        return {
          component: (
            <AddCategory
              initialValues={initialValuesAdd}
              validationSchema={validationSchema}
            />
          ),
          title: `${t('modal.add.title')}`,
        }
      case 'edit':
        return {
          component: (
            <EditCategory
              category={selectedCategory}
              initialValues={initialValuesEdit}
              validationSchema={validationSchema}
            />
          ),
          title: `${t('modal.edit.title')}`,
        }
      case 'delete':
        return {
          component: (
            <ModalConfirm
              content={deleteContent}
              deleteFunction={() =>
                deleteCategory(selectedCategory?.id as number)
              }
            />
          ),
          title: `${t('modal.delete.title')}`,
        }

      default:
        return { component: <DefaultModal />, title: `${t('modal.default')}` }
    }
  }
  const handleOpen = async (
    category: IHolidaysCategory | null,
    name: string
  ) => {
    category && setSelectedCategory(category)
    setModalName(name)
    handleOpenModal(name)
  }
  return (
    <Container>
      <HeaderTab translate="systemHolidaysCategories" id={t('header.id')} />
      {isUserPermitted(user, [permissionList.HOLIDAY_CATEGORY_CREATE]) && (
        <Button onClick={() => handleOpen(null, 'addCategory')}>
          {t('button')}
        </Button>
      )}
      {rows.length ? (
        <Fade in={true} timeout={timeoutAnimation}>
          <Box>
            <TableComponent rows={rows} columns={columns} />
          </Box>
        </Fade>
      ) : (
        <EmptyArrayCard message={t('table.message')} />
      )}
      <ModalComponent
        component={selectingModal().component}
        title={selectingModal().title}
      />
    </Container>
  )
}
export default HolidaysCategories

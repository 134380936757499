import { useAuth } from 'hooks/useAuth'
import { useTranslation } from 'react-i18next'
import palette from 'theme/palette'

import { StatusEnum } from './useVacationRequests'

export const useData = () => {
  const { t } = useTranslation('vacationRequests')
  const { user } = useAuth()

  const ButtonStyle = {
    fontWeight: 550,
    textTransform: 'capitalize',
    fontSize: '14px',
  }

  const states = [
    { id: 1, text: StatusEnum.pending, color: palette.primary.light },
    { id: 2, text: StatusEnum.rejected, color: palette.error.main },
    { id: 3, text: StatusEnum.approved, color: palette.success.main },
    { id: 4, text: StatusEnum.cancelled, color: palette.warning.main },
    { id: 5, text: t('panel2.all'), color: palette.info.main },
  ]

  const getStatusColor = (status) => {
    return states?.find((state) => state.text === status)?.color
  }

  const getNameOfVacationType = (vacationTypeId) => {
    const vacationType = user?.UserHolidays?.find(
      (vacationCategory) => vacationCategory?.id === vacationTypeId
    )
    return {
      name: vacationType?.HolidaysCategory.name,
      title: `${vacationType?.HolidaysCategory.name} (${t(
        'modal.add.availableDays'
      )}: ${(vacationType?.days || 0) - (vacationType?.usedDays || 0)}/${
        vacationType?.days
      }) ${vacationType?.year}`,
    }
  }

  return {
    ButtonStyle,
    getStatusColor,
    getNameOfVacationType,
    states,
  }
}

import { DatePicker, CalendarPickerView } from '@mui/x-date-pickers'
import { TextField, Typography } from '@mui/material'
import { addYears } from 'date-fns'
import { config } from 'config'

const CustomizedDataPicker = ({
  form,
  field,
  label,
  inputFormat,
  views,
  disabled,
  error,
}: {
  children: string
  form: { setFieldValue: (name: string, value: Date | null) => void }
  field: { name: string; value: string; id: string }
  label: string
  inputFormat: string
  views?: CalendarPickerView[]
  disabled?: boolean
  error?: Date
}) => {
  const { name, value } = field
  const { setFieldValue } = form
  return (
    <>
      <DatePicker
        label={label}
        value={value}
        inputFormat={inputFormat}
        onChange={(newValue: Date | null) => {
          setFieldValue(name, newValue)
        }}
        renderInput={(params) => <TextField fullWidth {...params} />}
        views={views}
        minDate={config.START_DATE}
        maxDate={addYears(config.START_DATE, config.AMOUNT_OF_YEARS_TO_ADD)}
        disabled={disabled}
      />
      {error && (
        <Typography
          variant="h6"
          color="error"
          sx={{ marginTop: '0.25rem', textIndent: '1rem' }}
        >
          {error}
        </Typography>
      )}
    </>
  )
}

export default CustomizedDataPicker

import * as yup from 'yup'
import { useState } from 'react'
import { Formik, Form, Field } from 'formik'
import { TextField } from 'formik-mui'
import {
  Box,
  Button,
  Grid,
  LinearProgress,
  Autocomplete,
  Checkbox,
  TextField as MuiTextField,
  Typography,
} from '@mui/material'
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank'
import CheckBoxIcon from '@mui/icons-material/CheckBox'
import { useTranslation } from 'react-i18next'
import { useGetAllTags } from 'hooks/tags'
import { IFilterPayload } from 'hooks/table'

import { AntSwitch } from '../AntSwitch/index.style'
const icon = <CheckBoxOutlineBlankIcon fontSize="small" />
const checkedIcon = <CheckBoxIcon fontSize="small" />
const SearchInputComponent = ({
  handleFilter,
}: {
  handleFilter: (payload: IFilterPayload) => void
}): JSX.Element => {
  const [onlyActive, setOnlyActive] = useState(true)
  const tagsList = useGetAllTags()
  const { t } = useTranslation('users')

  const validationSchema = yup.object({
    name: yup.string(),
  })

  const initialValues = {
    name: '',
    checked: [],
    active: true,
  }
  return (
    <Box sx={{ display: 'flex' }}>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={(values, { setSubmitting }) => {
          handleFilter({ ...values, active: onlyActive })
          setSubmitting(false)
        }}
        enableReinitialize
      >
        {({ isSubmitting, setFieldValue, values }) => (
          <Form
            style={{
              width: '100%',
            }}
          >
            <Grid container alignItems="center" columnSpacing={2} mb={3}>
              <Grid item xs={4}>
                <Field
                  fullWidth
                  key="userName"
                  component={TextField}
                  name="name"
                  label={t('search.label1')}
                  variant="outlined"
                />
              </Grid>

              <Grid item xs={6}>
                <Autocomplete
                  multiple
                  id="checkboxes-tags-demo"
                  options={tagsList}
                  disableCloseOnSelect
                  getOptionLabel={(option) => option.name}
                  renderOption={(props, option, { selected }) => (
                    <li {...props}>
                      <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={selected}
                      />
                      {option.name}
                    </li>
                  )}
                  onChange={(e, value) => {
                    const tagsId = value.map((tag) => tag.id)
                    setFieldValue('checked', tagsId)
                  }}
                  renderInput={(params) => (
                    <MuiTextField
                      {...params}
                      label={t('search.label2')}
                      placeholder={t('search.label2')}
                      name="checked"
                    />
                  )}
                />
              </Grid>
              <Grid item xs={2} justifyContent="center" alignItems="center">
                <Button disabled={isSubmitting} type="submit">
                  {t('search', { ns: 'buttons' })}
                </Button>
              </Grid>
              {isSubmitting && <LinearProgress />}
              <Grid
                item
                xs={12}
                display="flex"
                alignItems="center"
                gap="10px"
                mt={1}
              >
                <AntSwitch
                  name="active"
                  checked={onlyActive}
                  onChange={() => {
                    setOnlyActive((prevOnlyActive) => !prevOnlyActive),
                      handleFilter({ ...values, active: !onlyActive })
                  }}
                />
                <Typography>
                  {onlyActive ? t('search.label3') : t('search.label4')}
                </Typography>
              </Grid>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  )
}
export default SearchInputComponent

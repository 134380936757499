export enum HttpMethod {
  'GET',
  'POST',
  'PUT',
  'DELETE',
  'PATCH',
  'POST_DOWNLOAD',
  'GET_DOWNLOAD',
}

export enum APIRoutes {
  'ACTIVITY_LOG',
  'ALLOCATION_READ',
  'ALLOCATION_READ_BY_CUSTOMER',
  'ALLOCATIONS_ADD',
  'ALLOCATIONS_DELETEBYID',
  'ALLOCATIONS_UPDATE',
  'ALLOCATIONS_CHECKER',
  'ALLOCATIONS_GETBYPROJECTUSER',
  'ADD_AGREEMENT_TO_USER',
  'ADD_HOLIDAY_CATEGORY_TO_USER',
  'AGREEMENTS_GET',
  'ALL_WORKDAYS_DELETE',
  'APPLICATION_DELETE',
  'APPLICATION_GET_ONE',
  'APPLICATION_UPDATE',
  'APPLICATION_UPDATE_PARTIAL',
  'APPLICATIONS_GET_ALL',
  'APPLICATIONS_SAVE',
  'AUTH_LOGIN',
  'AUTH_LOGIN_BY_OTHER',
  'AUTH_ME',
  'BUSINESS_UNITS_ADD_USER',
  'BUSINESS_UNITS_CREATE',
  'BUSINESS_UNITS_DELETE',
  'BUSINESS_UNITS_DELETE_USER',
  'BUSINESS_UNITS_GETALL',
  'BUSINESS_UNITS_READ',
  'BUSINESS_UNITS_TOGGLE_FLAGS',
  'BUSINESS_UNITS_UPDATE',
  'CHECK_LOGIN',
  'CUSTOMERS_CREATE',
  'CUSTOMERS_DELETE',
  'CUSTOMERS_GETALL',
  'CUSTOMERS_GETONE',
  'CUSTOMERS_UPDATE',
  'CV_BIO_CREATE',
  'CV_BIO_DELETE',
  'CV_BIO_UPDATE',
  'CV_EDU_CREATE',
  'CV_EDU_DELETE',
  'CV_EDU_UPDATE',
  'CV_GENERATE',
  'CV_PREVIEW',
  'CV_PROFILE_CREATE',
  'CV_PROFILE_GET',
  'CV_PROFILE_UPDATE',
  'CV_PROJECT_CREATE',
  'CV_PROJECT_DELETE',
  'CV_PROJECT_UPDATE',
  'CV_TEMPLATES_GET',
  'DELETE_AGREEMENT_TO_USER',
  'DELETE_HOLIDAY_CATEGORY_FROM_USER',
  'EMPLOYEE_NOTES',
  'EMPLOYEE_NOTES_ADD',
  'EMPLOYEE_NOTES_DELETE',
  'EMPLOYEE_NOTES_UPDATE',
  'GENERATE_VACATION_REQUEST',
  'GET_USERDETAILS',
  'GROUPS_ADDUSER',
  'GROUPS_ADDPERMISSION',
  'GROUPS_CREATE',
  'GROUPS_DELETE',
  'GROUPS_DELETEPERMISSION',
  'GROUPS_GETALL',
  'GROUPS_UPDATE',
  'HELPFULUSERS_GET',
  'HOLIDAY_CATEGORY_CREATE',
  'HOLIDAY_CATEGORY_DELETE',
  'HOLIDAY_CATEGORY_UPDATE',
  'HOLIDAYS_CATEGORIES_GETALL',
  'HOLIDAYS_GETALL',
  'HOLIDAYS_CREATE',
  'HOLIDAYS_DELETE',
  'HOLIDAYS_SYNC',
  'UPDATE_HOLIDAY_CATEGORY_USER',
  'INVENTORIES_ADD_TO_USER',
  'INVENTORIES_DELETE_FROM_USER',
  'INVENTORIES_GET_FREE',
  'INVENTORIES_GETALL',
  'INVENTORIES_UPDATE_TO_USER',
  'INVENTORY_CREATE',
  'INVENTORY_DELETE',
  'INVENTORY_UPDATE',
  'LOCATIONS_ADD_USER',
  'LOCATIONS_CREATE',
  'LOCATIONS_DELETE',
  'LOCATIONS_DELETE_USER',
  'LOCATIONS_GETALL',
  'LOCATIONS_READ',
  'LOCATIONS_TOGGLEDIRECTOR',
  'LOCATIONS_UPDATE',
  'NOTIFICATIONS_CREATE',
  'NOTIFICATIONS_DELETE',
  'NOTIFICATIONS_GET',
  'PERMISSION_CREATE',
  'PERMISSIONS_DELETE',
  'PERMISSIONS_GETALL',
  'PKUP_CHANGE_APPROVAL',
  'PKUP_CREATE',
  'PKUP_CREATE_WORK',
  'PKUP_GET_ALL',
  'PKUP_GET_ALL_FOR_ME',
  'PKUP_GET_ALL_FOR_USER',
  'PKUP_GET_ALL_FOR_REPORT',
  'PKUP_GET_ALL_WORK_FOR_PKUP',
  'PKUP_GET_ALL_WORKS_FOR_ME',
  'PKUP_GET_ATTACHMENT',
  'PKUP_GET_ONE',
  'PKUP_GET_REVISION',
  'PKUP_GET_ZIP',
  'PKUP_GET_ZIP_FOR_ME',
  'PKUP_UPDATE',
  'PKUP_UPDATE_STATE',
  'PROJECTS_ADDUSER',
  'PROJECTS_CREATE',
  'PROJECTS_CREATETIMEFRAMES',
  'PROJECTS_DELETE',
  'PROJECTS_DELETETIMEFRAMES',
  'PROJECTS_DELETEUSER',
  'PROJECTS_GETALL',
  'PROJECTS_GETALL_FOR_PKUP',
  'PROJECTS_GETONE',
  'PROJECTS_GETONE_CALENDAR',
  'PROJECTS_GETONE_HISTORY',
  'PROJECTS_TOGGLEPM',
  'PROJECTS_UPDATE',
  'REMIND_PASSWORD',
  'REMIND_PASSWORD_CHECK',
  'REPORT_GENERATE_ALLOCATIONS_FOR_CUSTOMERS',
  'REPORT_GENERATE_ALLOCATIONS_FOR_USERS',
  'REPORT_GENERATE_FOR_CUSTOMER',
  'REPORT_GENERATE_FOR_CUSTOMERS',
  'REPORT_GENERATE_FOR_PROJECTS',
  'REPORT_GENERATE_PER_PROJECT',
  'REPORT_GENERATE_WORKDAYS',
  'REPORT_GET_PROJECT_CODES',
  'REPORT_GET_CUSTOMERS',
  'RESET_PASSWORD',
  'TAGS_ADD_RATE',
  'TAGS_ADD_TO_PROJECT',
  'TAGS_ADD_TO_USER',
  'TAGS_DELETE',
  'TAGS_DELETE_FROM_PROJECT',
  'TAGS_DELETE_FROM_USER',
  'TAGS_GET',
  'TAGS_MULTIPLE_CREATE',
  'TAGS_MULTIPLE_GET',
  'USERTIMEFRAMES_GET',
  'UPDATE_AGREEMENT_TO_USER',
  'UPDATE_ME',
  'UPLOAD_OLD_EXCEL',
  'UPLOAD_OLD_EXCEL_FINAL',
  'USER_GET',
  'USER_GET_APPLICATIONS',
  'USER_DELETE',
  'USER_SAVE',
  'USER_SAVE_APPLICATION',
  'USER_UPDATE',
  'USER_UPDATE_PARTIAL',
  'USERS_CREATE',
  'USERS_DELETEGROUP',
  'USERS_GET_ALL',
  'USERS_GETONE_HISTORY',
  'USERS_UPDATE',
  'USERS_GET_BIO',
  'USERS_GET_CV_PROJECTS',
  'USERS_GET_EDU',
  'USERS_GETLIST',
  'USERS_GETONE',
  'USERS_WITH_END_AGREEMENTS_GET',
  'VACATION_REQUEST_ADD',
  'VACATION_REQUEST_DELETE',
  'VACATION_REQUESTS_GET',
  'VACATION_REQUEST_GET_APPROVERS',
  'VACATION_REQUEST_GET_PDF',
  'VACATION_REQUEST_UPDATE',
  'VACATION_REQUEST_UPDATE_STATUS',
  'VACATION_REQUEST_VALIDATE',
  'WORKDAYS_COPY',
  'WORKDAYS_CREATE',
  'WORKDAYS_DOWNLOADFORCUSTOMER',
  'WORKDAYS_DOWNLOADFORME',
  'WORKDAYS_DOWNLOADFORPROJECT',
  'WORKDAYS_DOWNLOADFORPROJECTS',
  'WORKDAYS_DOWNLOADFORUSER',
  'WORKDAYS_DOWNLOADFORUSERS',
  'WORKDAYS_DOWNLOADUOPFORME',
  'WORKDAYS_DOWNLOADUOPFORUSER',
  'WORKDAYS_DOWNLOADUOPFORUSERS',
  'WORKDAYS_DELETE',
  'WORKDAYS_GETFORME',
  'WORKDAYS_GETFORUSER',
  'WORKDAYS_HOURS_IN_MONTH',
  'WORKDAYS_READ_FILTERED',
  'WORKDAYS_TOREVIEW',
  'WORKDAYS_TOREVIEW_ACCEPT',
  'WORKDAYS_TOREVIEW_DECLINE',
  'WORKDAYS_UPDATE',
  'WORKDAYS_UPDATEFORUSER',
  'WORKTYPE_CREATE',
  'WORKTYPE_DELETE',
  'WORKTYPE_GETALL',
  'WORKTYPE_UPDATE',
}

import { Grid, Grow } from '@mui/material'
import { CardItem } from 'components/common'
import { useAuth } from 'hooks/useAuth'

import { StyledGrid, StyledBox } from '../../index.style'

export interface ICardContent {
  title: string
  subtitle: string | JSX.Element
  icon?: JSX.Element
  subtitle2?: string | JSX.Element
  component?: JSX.Element
  button?: JSX.Element
  secondButton?: JSX.Element
  backgroundColor?: string
}

interface IListOfCards {
  content: ICardContent
  timeout: number
}

interface IProps {
  listOfCards: IListOfCards[]
  businessUnitsCards: ICardContent[] | undefined
}

const GridItem = ({ children }: { children: React.ReactNode }) => {
  const { user } = useAuth()
  return (
    <StyledGrid item xs={6} sm={user?.businessUnits?.length ? 3 : 4} md={3}>
      {children}
    </StyledGrid>
  )
}

const Cards: React.FC<IProps> = ({ listOfCards, businessUnitsCards }) => {
  return (
    <Grid container spacing={3} flexWrap="wrap" marginBottom={4} px={2.5}>
      {listOfCards.map(({ content, timeout }) => (
        <GridItem key={content.title}>
          <Grow in={true} timeout={{ enter: timeout }}>
            <StyledBox>
              <CardItem cardContent={content} />
            </StyledBox>
          </Grow>
        </GridItem>
      ))}
      {businessUnitsCards?.map((business) => (
        <GridItem key={business.title}>
          <Grow in={true} timeout={{ enter: 3000 }}>
            <StyledBox>
              <CardItem cardContent={business} />
            </StyledBox>
          </Grow>
        </GridItem>
      ))}
    </Grid>
  )
}

export default Cards

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Box, Typography } from '@mui/material'
import { IValidateVacationData } from 'pages/VacationRequest/types'
import { useFormikContext } from 'formik'

import { IInitialValues } from '../VacationRequestFormik'

interface VacationRequestValidateInfoProps {
  data?: IValidateVacationData
}

const VacationRequestValidateInfo = ({
  data,
}: VacationRequestValidateInfoProps) => {
  const { t } = useTranslation(['vacationRequests'])
  const { values } = useFormikContext<IInitialValues>()
  const [error, setError] = useState<string | null>(null)
  const { valueStartDate, valueEndDate } = values

  useEffect(() => {
    if (
      valueStartDate &&
      valueEndDate &&
      valueStartDate.getTime() > valueEndDate.getTime()
    ) {
      setError(t('modal.add.error'))
    } else {
      setError(null)
    }
  }, [valueStartDate, valueEndDate])

  return (
    <Box
      sx={{
        minHeight: '3.3rem',
      }}
    >
      {error ? (
        <Typography variant="subtitle1">
          {t('modal.add.numberOfDays')}:{' '}
          <Typography variant="subtitle1" color="error">
            {t('modal.add.error')}
          </Typography>
        </Typography>
      ) : (
        data && (
          <>
            <Typography variant="subtitle1">
              {t('modal.add.numberOfDays')}: <span>{data.daysCount}</span>
            </Typography>
            <Typography variant="subtitle1">
              {t('modal.add.vacationDates')}: {data.affectedDates.join(', ')}
            </Typography>
          </>
        )
      )}
    </Box>
  )
}

export default VacationRequestValidateInfo

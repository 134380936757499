import { Box } from '@mui/material'
import { GridRowsProp } from '@mui/x-data-grid'
import { useTranslation } from 'react-i18next'
import { IPaginateReducer } from 'hooks/table'
import { TableComponent } from 'components/common'

interface IProps {
  historyRows: GridRowsProp
  total: number
  handleChangePage?: any
  paginationUC?: IPaginateReducer
  handleChangeRowsPerPage?: (event: React.ChangeEvent<HTMLInputElement>) => void
}

const HistoryTable: React.FC<IProps> = ({
  historyRows,
  total,
  handleChangePage,
  paginationUC,
  handleChangeRowsPerPage,
  children,
}): JSX.Element => {
  const { t } = useTranslation('userEditPage')
  const columns = [
    `${t('history.table.columns.col1')}`,
    `${t('history.table.columns.col2')}`,
    `${t('history.table.columns.col3')}`,
    `${t('history.table.columns.col4')}`,
  ]

  const rows: GridRowsProp = historyRows

  return (
    <Box>
      {children}
      <TableComponent
        columns={columns}
        rows={rows}
        total={total}
        pagination={true}
        handleChangePage={handleChangePage}
        paginationUC={paginationUC}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Box>
  )
}

export default HistoryTable

import {
  createContext,
  useState,
  Dispatch,
  SetStateAction,
  useContext,
  useEffect,
} from 'react'
import { format, addMonths, getQuarter } from 'date-fns'
import { useMutation, UseMutateFunction } from 'react-query'

import {
  IAllocationByCustomer,
  IAllocationByUsers,
  IReportData,
} from '../types'

import { getAllocationByUser, getAllocationByCustomer } from './utils'

export type ReportContent = {
  getUsersAllocations: UseMutateFunction<
    IReportData,
    unknown,
    IReportData,
    unknown
  >
  getCustomerAllocations: UseMutateFunction<
    IReportData,
    unknown,
    IReportData,
    unknown
  >
  dates: string[]
  setDates: Dispatch<SetStateAction<string[]>>
  handleDates: (newDate: Date) => void
  allocationByCustomer: IAllocationByCustomer[] | []
  allocationByUsers: IAllocationByUsers[] | []
}
export const ReportAllocationContext = createContext<ReportContent>(
  {} as ReportContent
)

export const ReportAllocationProvider: React.FC<React.ReactNode> = ({
  children,
}) => {
  const [dates, setDates] = useState<string[] | []>([])
  const [allocationByUsers, setAllocationByUsers] = useState<
    IAllocationByUsers[] | []
  >([])
  const [allocationByCustomer, setAllocationByCustomer] = useState<
    IAllocationByCustomer[] | []
  >([])

  const { mutate: getUsersAllocations } = useMutation(
    (reportData: IReportData) => getAllocationByUser(reportData, dates),
    {
      onSuccess: (data) => {
        setAllocationByUsers(data)
      },
    }
  )
  const { mutate: getCustomerAllocations } = useMutation(
    (reportData: IReportData) => getAllocationByCustomer(reportData, dates),
    {
      onSuccess: (data) => {
        setAllocationByCustomer(data)
      },
    }
  )

  const handleDates = (newDate: Date) => {
    const month = newDate.getMonth()
    const year = newDate.getFullYear()
    const day = newDate.getDate()
    const quarterNumber = getQuarter(new Date(year, month, day))
    const findQuarterDates = (): number => {
      switch (quarterNumber) {
        case 2:
          return 3
        case 3:
          return 6
        case 4:
          return 9
        default:
          return 0
      }
    }
    const date1 = addMonths(new Date(year, findQuarterDates(), day), 0)
    const date2 = addMonths(new Date(year, findQuarterDates(), day), 1)
    const date3 = addMonths(new Date(year, findQuarterDates(), day), 2)
    const dates = [
      format(new Date(date1), 'yyyy/MM'),
      format(new Date(date2), 'yyyy/MM'),
      format(new Date(date3), 'yyyy/MM'),
    ]
    setDates(dates)
  }

  useEffect(() => {
    handleDates(new Date())
  }, [])

  useEffect(() => {
    if (allocationByUsers?.length) {
      allocationByUsers.splice(-1, 1)
    }
  }, [allocationByUsers])

  useEffect(() => {
    if (allocationByCustomer?.length) {
      allocationByCustomer.splice(-1, 1)
    }
  }, [allocationByCustomer])

  const value = {
    getUsersAllocations,
    getCustomerAllocations,
    allocationByUsers,
    allocationByCustomer,
    dates,
    setDates,
    handleDates,
  }
  return (
    <ReportAllocationContext.Provider value={value}>
      {children}
    </ReportAllocationContext.Provider>
  )
}

export const useReportAllocationContext = () =>
  useContext(ReportAllocationContext) as ReportContent

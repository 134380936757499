import { useNavigate } from 'react-router-dom'
import { useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { IPagination, IUsers } from 'hooks/types'
import { IPaginateReducer, defaultPaginationValue } from 'hooks/table'

export interface ITag {
  id: number
  name: string
}

export type IData = IPagination & { data: IUsers[] }

export const useUsers = () => {
  const navigate = useNavigate()
  const [paginationUc, setPaginationUc] = useState<IPaginateReducer>({
    ...defaultPaginationValue,
    filters: JSON.stringify({ inactive: false }),
  })

  const changePagination = (pagination: IPaginateReducer) => {
    setPaginationUc(pagination)
  }
  const filters = paginationUc?.filters
    ? `&filters=${paginationUc?.filters}`
    : ''
  const searchInput = paginationUc?.search
    ? `&search=${paginationUc?.search}`
    : ''
  const orderBy = paginationUc?.order
    ? `&order={"order":"${paginationUc.order.order}","column":"${paginationUc.order.column}"}`
    : ''
  const params = `page=${paginationUc?.currentPage + 1 || 1}&limit=${
    paginationUc?.length || 10
  }${filters}${searchInput}${orderBy}`
  useEffect(() => {
    navigate(`?${params}`)
  }, [paginationUc])
  async function getUsersPaged(): Promise<IData> {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.USERS_GET_ALL,
      {},
      {},
      `${params}`
    )
    return data
  }
  const fallback = <IData>{}

  const { data: usersList = fallback, refetch } = useQuery(
    queryKeys.users,
    getUsersPaged
  )
  useEffect(() => {
    refetch()
  }, [paginationUc])

  return {
    usersList,
    changePagination,
    paginationUc,
  }
}

import { useReducer, useEffect } from 'react'

import { paginationUCReducer } from './paginationReducer'
import { IPaginateReducer, IFilterPayload } from './types'
import { ChangeItemsInPage } from './enum'

export const defaultPaginationValue = {
  length: 10,
  currentPage: 0,
  search: null,
  order: null,
  filters: null,
}

export const useTablePagedFunction = (
  changePagination?: (pagination: IPaginateReducer) => void
) => {
  const [paginationUC, dispatchUC] = useReducer(
    paginationUCReducer,
    defaultPaginationValue
  )

  useEffect(() => {
    if (changePagination) {
      changePagination(paginationUC)
    }
  }, [paginationUC])

  const handleChangePage = (event: unknown, newPage: number) => {
    dispatchUC({
      type: ChangeItemsInPage.CHANGE_PAGE,
      payload: {
        currentPage: newPage,
      },
    })
  }
  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatchUC({
      type: ChangeItemsInPage.MULTI_EDIT_STATE,
      payload: {
        length: parseInt(event.target.value, 10),
        currentPage: 0,
      },
    })
  }

  const handleFilter = (payload: IFilterPayload) => {
    dispatchUC({
      type: ChangeItemsInPage.MULTI_EDIT_STATE,
      payload: {
        length: paginationUC.length,
        currentPage: 0,
        filters: JSON.stringify({
          tags: payload.checked,
          inactive: !payload.active,
        }),

        search: payload.name,
      },
    })
  }

  const sortBy = (column: string, orderBy: string) => {
    const order = paginationUC.order
    if (order && column === order.column) {
      orderBy = order.order === 'ASC' ? 'DESC' : 'ASC'
    }
    dispatchUC({
      type: ChangeItemsInPage.ORDER,
      payload: {
        order: orderBy,
        column,
      },
    })
  }

  return {
    dispatchUC,
    handleChangePage,
    handleChangeRowsPerPage,
    handleFilter,
    paginationUC,
    sortBy,
  }
}

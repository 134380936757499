import { Typography, Paper } from '@mui/material'
import React from 'react'

interface IProps {
  message: string
  styles?: React.CSSProperties
}
const EmptyArrayCard: React.FC<IProps> = ({ message, children, styles }) => {
  return (
    <Paper
      sx={{
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        padding: '0.5rem',
        margin: '1rem 0',
        ...styles,
      }}
    >
      <Typography>{message}</Typography>
      {children}
    </Paper>
  )
}
export default EmptyArrayCard

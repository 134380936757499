import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import {
  Box,
  Container,
  Button,
  Fade,
  Grid,
  IconButton,
  Tooltip,
  Typography,
  Chip,
} from '@mui/material'
import MoreHorizIcon from '@mui/icons-material/MoreHoriz'
import { GridRowsProp } from '@mui/x-data-grid'
import EditIcon from '@mui/icons-material/Edit'
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import NotesIcon from '@mui/icons-material/Notes'
import AccountBoxIcon from '@mui/icons-material/AccountBox'
import AddLocationIcon from '@mui/icons-material/AddLocation'
import { HeaderTab } from 'views/LayoutUI/components'
import {
  DetailsTooltip,
  EmptyArrayCard,
  ModalComponent,
  SearchInputComponent,
  TableComponent,
  useNotifications,
} from 'components/common'
import { useGetWorkdays } from 'hooks/calendar/useGetWorkdays'
import { useTablePagedFunction } from 'hooks/table'
import { orderIcon, timeoutAnimation } from 'utils'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import { useEffect } from 'react'
import { useQueryClient } from 'react-query'
import { PageRoutes } from 'routes'

import { useUsers } from './utils'
import { AddNewUser } from './modals'

const Users: React.FC = (): JSX.Element => {
  const { t } = useTranslation(['users', 'buttons'])
  const { getWorkdaysForUser } = useGetWorkdays()
  const navigate = useNavigate()
  const {
    currentCalendarDate,
    setSelectedUserWorkDays,
    userDetailsId,
    setUserDetailsId,
    handleOpenModal,
  } = useNotifications()

  const { usersList, changePagination, paginationUc } = useUsers()
  const {
    handleFilter,
    sortBy,
    paginationUC,
    handleChangePage,
    handleChangeRowsPerPage,
  } = useTablePagedFunction(changePagination)
  const queryClient = useQueryClient()

  useEffect(() => {
    queryClient.removeQueries('userOne')
  }, [])

  const columns = [
    <>
      <Button
        onClick={() => sortBy('id', 'ASC')}
        variant="text"
        sx={{ fontWeight: 550 }}
      >
        #{orderIcon(paginationUc?.order, 'id')}
      </Button>
    </>,
    <>
      <Button
        onClick={() => sortBy('name', 'ASC')}
        variant="text"
        sx={{ fontWeight: 550 }}
      >
        {t('table.cell2')} {orderIcon(paginationUc?.order, 'name')}
      </Button>
    </>,
    <>
      <Button
        onClick={() => sortBy('email', 'ASC')}
        variant="text"
        sx={{ fontWeight: 550 }}
      >
        {t('table.cell3')} {orderIcon(paginationUc?.order, 'email')}
      </Button>
    </>,
    `${t('table.cell4')}`,
    '',
  ]

  const buttonsArr = [
    {
      title: `${t('edit', { ns: 'buttons' })}`,
      icon: <EditIcon />,
      path: PageRoutes.USER,
    },
    {
      title: `${t('table.tooltip2')}`,
      path: PageRoutes.USER_CALENDAR,
      icon: <CalendarMonthIcon />,
    },
    {
      title: `${t('table.tooltip3')}`,
      path: PageRoutes.USER_ALLOCATIONS,
      icon: <AddLocationIcon />,
    },
    {
      title: `${t('table.tooltip4')}`,
      path: PageRoutes.USER_CV,
      icon: <AccountBoxIcon />,
    },
    {
      title: `${t('table.tooltip5')}`,
      path: PageRoutes.USER_NOTES,
      icon: <NotesIcon />,
    },
  ]

  const mapArr = usersList?.data?.map((user) => {
    const isActive = user.isActive ? (
      <ThumbUpIcon color="success" />
    ) : (
      <ThumbDownIcon color="error" />
    )

    const skills = user.tags?.map((tag, i) => (
      <Typography
        variant="h1"
        key={tag.id}
        sx={{ fontSize: '12px', color: 'white' }}
      >
        {tag.name}
        {tag?.UserTag?.skillLevel && `(${tag?.UserTag?.skillLevel})`}
        {i + 1 !== user?.tags?.length && ','}
      </Typography>
    ))
    return {
      id: user.id,
      name: (
        <DetailsTooltip id={userDetailsId}>
          <Box>
            <Typography
              onMouseOver={() => setUserDetailsId(user.id)}
              sx={{ cursor: 'pointer' }}
            >
              {user.firstName} {user.lastName}
            </Typography>
          </Box>
        </DetailsTooltip>
      ),

      email: (
        <Grid container spacing={1}>
          <Grid item xs={12}>
            {user.email}
          </Grid>

          <Grid item xs={12} sx={{ display: 'flex', gap: '5px' }}>
            {user.tags?.slice(0, 4).map((tag) => (
              <Chip key={tag.id} size="small" label={tag.name} />
            ))}
            {user.tags !== undefined && user?.tags?.length > 4 && (
              <Tooltip
                title={
                  <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                    {skills}
                  </Box>
                }
              >
                <Chip
                  key="more"
                  label={<MoreHorizIcon />}
                  size="small"
                  sx={{
                    cursor: 'pointer',
                    '& .MuiChip-label': {
                      display: 'flex',
                      alignItems: 'center',
                    },
                  }}
                />
              </Tooltip>
            )}
          </Grid>
        </Grid>
      ),
      active: <Box textAlign="center">{isActive}</Box>,
      button: (
        <Box sx={{ display: 'flex', justifyContent: 'right' }}>
          {buttonsArr.map((button) => (
            <Tooltip title={button.title} key={button.title}>
              <IconButton
                onClick={() => {
                  if (button.title === `${t('table.tooltip2')}`) {
                    getWorkdaysForUser(
                      currentCalendarDate,
                      user.id.toString()
                    ).then((res) => {
                      setSelectedUserWorkDays(res)
                    })
                  }
                  navigate(button.path.replace(':id', `${user.id}`))
                }}
              >
                {button.icon}
              </IconButton>
            </Tooltip>
          ))}
        </Box>
      ),
    }
  })

  const rows: GridRowsProp = mapArr
  return (
    <Container>
      <HeaderTab translate="users" />
      <Button
        sx={{ marginBottom: '10px' }}
        onClick={() => handleOpenModal('addNewUser')}
      >
        {t('add')}
      </Button>
      <SearchInputComponent handleFilter={handleFilter} />
      {usersList?.data?.length ? (
        <Fade in={true} timeout={timeoutAnimation}>
          <Box>
            <TableComponent
              columns={columns}
              rows={rows}
              total={usersList?.total}
              pagination={true}
              handleChangePage={handleChangePage}
              paginationUC={paginationUC}
              handleChangeRowsPerPage={handleChangeRowsPerPage}
            />
          </Box>
        </Fade>
      ) : (
        <EmptyArrayCard message={t('table.message')} />
      )}
      <ModalComponent component={<AddNewUser />} title={t('add')} />
    </Container>
  )
}
export default Users

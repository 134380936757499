import { Formik } from 'formik'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'
import { IVacationRequest } from 'pages/VacationRequest/hooks/useVacationRequests'
import { useAuth } from 'hooks/useAuth'

import VacationRequestForm from '../VacationRequestForm'

export interface IInitialValues {
  valueStartDate: Date
  valueEndDate: Date
  comment: string
  vacationType?: { id: number; name: string }
  workType?: { id: number | null; name: string }
  project?: { id: number | null; name: string } | null
}

interface VacationRequestFormikProps {
  initialValues: IInitialValues
  onSubmit: ({ values, vacationRequestId }) => void
  selectedRequest?: IVacationRequest | undefined
}
const VacationRequestFormik: React.FC<VacationRequestFormikProps> = ({
  initialValues,
  onSubmit,
  selectedRequest,
}): JSX.Element => {
  const { t } = useTranslation('vacationRequests')
  const { user } = useAuth()

  const validationSchema = yup.object({
    vacationType: yup
      .object()
      .required(`${t('vacationType.required', { ns: 'validation' })}`),
    workType: yup
      .object()
      .required(`${t('worktype.required', { ns: 'validation' })}`),
    project: yup
      .object()
      .when('workType', {
        is: (workType) =>
          user?.system?.workTypes.find(
            (worktype) => worktype.id === workType?.id
          )?.isAssignableToProject,
        then: yup
          .object()
          .required(t('project.required', { ns: 'validation' }))
          .nullable(),
      })
      .nullable(),
    valueStartDate: yup
      .date()
      .required(`${t('startDate.required', { ns: 'validation' })}`),
    valueEndDate: yup
      .date()
      .required(`${t('endDate.required', { ns: 'validation' })}`),
  })

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, { setSubmitting }) => {
        onSubmit({
          values,
          vacationRequestId: selectedRequest?.id,
        })
        setSubmitting(false)
      }}
    >
      <VacationRequestForm initialValues={initialValues} />
    </Formik>
  )
}

export default VacationRequestFormik

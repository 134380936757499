import { GridRowsProp } from '@mui/x-data-grid'
import { Button, Box, Typography, IconButton } from '@mui/material'
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import SyncIcon from '@mui/icons-material/Sync'
import { useTranslation } from 'react-i18next'
import { IUserProps } from 'components/users'
import {
  EmptyArrayCard,
  TableComponent,
  useNotifications,
} from 'components/common'
import { useHolidaysSync } from 'hooks/useHolidaysSync'
import { isUserPermitted, permissionList } from 'services'

import { IHolidaysProps } from '../../types'

import AddHoliday from './AddHoliday'
import { useUserHolidays } from './utils'
import { StyledBadge } from './index.style'

interface IHolidays {
  name: string
  year: number
  availableDays: number
  usedDays: number
}

const HolidaysDetails: React.FC<IUserProps & IHolidaysProps> = ({
  user,
  setUserHoliday,
}): JSX.Element => {
  const { t } = useTranslation('userEditPage')
  const { deleteHolidayFromUser } = useUserHolidays()
  const columns = [
    `${t('holidays.table.columns.col1')}`,
    `${t('holidays.table.columns.col2')}`,
    `${t('holidays.table.columns.col3')}`,
    `${t('holidays.table.columns.col4')}`,
    '',
  ]
  const { handleOpenModal } = useNotifications()
  const getHolidaysSync = useHolidaysSync()

  const handleEdit = (id: number) => {
    handleOpenModal('editHoliday')
    const userHolidays = user?.UserHolidays?.find(
      (userHoliday) => userHoliday.id === id
    )
    if (userHolidays) {
      setUserHoliday(userHolidays)
    }
  }

  const handleDelete = (id: number) => {
    const userHoliday = user?.UserHolidays?.find(
      (userHoliday) => userHoliday.id === id
    )

    if (userHoliday) {
      setUserHoliday(userHoliday)
    }
    handleOpenModal('deleteHoliday')
  }

  const userHoliday = user?.UserHolidays?.map((holiday) => {
    return {
      name: holiday?.HolidaysCategory?.name,
      year: holiday.year,
      availableDays: holiday.days,
      usedDays: holiday.usedDays,
      buttons: (
        <>
          <IconButton onClick={() => handleEdit(holiday.id)}>
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() =>
              holiday.usedDays
                ? handleDelete(holiday.id)
                : deleteHolidayFromUser(holiday.id)
            }
          >
            <DeleteIcon color="error" />
          </IconButton>
        </>
      ),
    }
  }) as IHolidays[]

  const rows: GridRowsProp = userHoliday

  return (
    <>
      <Box>
        <Typography variant="h3">{t('holidays.title1')}</Typography>
        <Box>
          <Box display="flex">
            <Typography>
              {t('holidays.subTitle1')}
              {'\u00a0'}
            </Typography>{' '}
            <StyledBadge>{user?.holidaysInThisYear}</StyledBadge>
          </Box>
          <Box display="flex" my={1}>
            <Typography>{t('holidays.subTitle2')} </Typography>
            <StyledBadge>{user?.maxHolidaysDays}</StyledBadge>
          </Box>
          {!!user?.locations.length && (
            <Box display="flex">
              <Typography> {t('holidays.subTitle3')}</Typography>
              <Box display="flex" flexWrap="wrap">
                {user?.locations?.map((location) => (
                  <StyledBadge key={location.id}>
                    {location.name}{' '}
                    {location?.UserLocation.isDirector
                      ? t('holidays.isDirector')
                      : ' '}
                  </StyledBadge>
                ))}
              </Box>
            </Box>
          )}
        </Box>
      </Box>
      <Box mt={4} maxWidth={500}>
        {userHoliday?.length ? (
          <>
            {isUserPermitted(user, [permissionList.HOLIDAY_SYNC]) && (
              <Button
                variant="outlined"
                startIcon={<SyncIcon />}
                onClick={() => getHolidaysSync(user?.id)}
              >
                {t('holidays.syncUserHolidays')}
              </Button>
            )}
            <TableComponent columns={columns} rows={rows} />
          </>
        ) : (
          <EmptyArrayCard message={t('holidays.noHolidays')} />
        )}
      </Box>
      <Box mt={4}>
        <Typography variant="h3">{t('holidays.title3')}</Typography>
        <AddHoliday />
      </Box>
    </>
  )
}
export default HolidaysDetails

const NUMBER_OF_LI_TO_ANIMATE = 10

const BackgroundAnimation = () => {
  let listOfCircles: JSX.Element[] = []
  for (let i = 0; i < NUMBER_OF_LI_TO_ANIMATE; i++) {
    listOfCircles = [...listOfCircles, <li key={i}></li>]
  }
  return <ul className="circles">{listOfCircles}</ul>
}

export default BackgroundAnimation

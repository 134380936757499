import { useEffect, useState } from 'react'
import { Field, useFormikContext } from 'formik'
import { TextField } from 'formik-mui'
import { Box, Grid, Button, LinearProgress, Typography } from '@mui/material'
import { useTranslation } from 'react-i18next'
import {
  CustomAutocompleteInput,
  CustomizedDataPicker,
} from 'components/common'
import { useAuth } from 'hooks/useAuth'
import { useWorkTypes } from 'utils'

import { IInitialValues } from '../VacationRequestFormik'
import { useDataFields, useVacationRequests } from '../../hooks'

import { StyledForm } from './index.style'
import VacationRequestValidateInfo from './VacationRequestValidateInfo'
import { getDefaultWorkType } from './helpers/getDefaultWorkType'

interface VacationRequestFormProps {
  initialValues: IInitialValues
}
const VacationRequestForm: React.FC<VacationRequestFormProps> = ({
  initialValues,
}): JSX.Element => {
  const { t } = useTranslation(['vacationRequests', 'save'])
  const { user } = useAuth()
  const { validateVacationRequest, validateVacationRequestData } =
    useVacationRequests(user?.id)
  const { values, errors, setFieldValue, isSubmitting, submitForm, isValid } =
    useFormikContext<IInitialValues>()
  const vacationDates = useDataFields()
  const {
    isWorkTypeHoliday,
    isWorkTypeAssignableToProject,
    projects,
    workTypesOnlyForSelectedProject,
  } = useWorkTypes()

  const [userProjects, setUserProjects] = useState(projects)
  const optionsUserVacationTypes = () => {
    return user?.UserHolidays?.filter(
      (vacationCategory) => vacationCategory.usedDays !== vacationCategory.days
    ).map((vacationCategory) => {
      return {
        id: vacationCategory?.id,
        name: `${vacationCategory.HolidaysCategory.name} (${t(
          'modal.add.availableDays'
        )}: ${vacationCategory.days - vacationCategory.usedDays}/${
          vacationCategory.days
        }) ${vacationCategory.year}`,
      }
    })
  }

  useEffect(() => {
    isWorkTypeHoliday(values?.workType?.id)
      ? setFieldValue(
          'vacationType',
          getDefaultWorkType(optionsUserVacationTypes()) ||
            initialValues.vacationType
        )
      : setFieldValue('vacationType', { id: null, name: 'Unexpected leave' })

    const selectedWorkType = user?.system?.workTypes?.find(
      (worktype) => worktype.id === values?.workType?.id
    )
    selectedWorkType?.projectId
      ? setUserProjects(
          projects.filter(
            (project) => project.id === selectedWorkType?.projectId
          )
        )
      : setUserProjects(projects)
    isWorkTypeAssignableToProject(values?.workType?.id)
      ? setFieldValue('project', initialValues.project)
      : setFieldValue('project', null)
  }, [values.workType])

  useEffect(() => {
    if (
      values[vacationDates[0].name] &&
      values[vacationDates[1].name] &&
      isValid
    ) {
      validateVacationRequest({
        values,
      })
    }
  }, [values])

  const getWorkType = () => {
    return user?.system?.workTypes.find(
      (worktype) => worktype.id === values?.workType?.id
    )
  }

  return (
    <StyledForm>
      <Box mb={2}>
        <Field
          component={CustomAutocompleteInput}
          optionsData={
            workTypesOnlyForSelectedProject(
              values?.project?.id,
              'VacationRequests'
            ) || []
          }
          name="workType"
          label={t('modal.add.selectWorktype')}
          disableClearable={true}
          error={errors?.workType}
        />
      </Box>
      <Box mb={2}>
        {getWorkType()?.isAssignableToProject || getWorkType()?.isHoliday ? (
          <>
            <Field
              component={CustomAutocompleteInput}
              optionsData={userProjects || []}
              name="project"
              label={t('modal.add.selectProject')}
              disableClearable={false}
              error={errors?.project}
            />
            {!userProjects.length && (
              <Typography variant="subtitle1">
                {t('modal.add.assignedProjects')}
              </Typography>
            )}
          </>
        ) : null}

        {!getWorkType()?.isAssignableToProject && getWorkType()?.isHoliday && (
          <Typography variant="subtitle1">
            {t('modal.add.projectIsOptional')}
          </Typography>
        )}
      </Box>
      {isWorkTypeHoliday(values?.workType?.id) && (
        <Box mb={2}>
          <Field
            component={CustomAutocompleteInput}
            optionsData={optionsUserVacationTypes() || []}
            name="vacationType"
            label={t('modal.add.selectTypeOfVacation')}
            disableClearable={true}
            error={errors?.vacationType}
          />
        </Box>
      )}
      <Box mb={2}>
        <Field
          fullWidth
          component={TextField}
          name="comment"
          type="text"
          label={t('modal.add.comment')}
          variant="outlined"
          size="small"
          InputLabelProps={{
            shrink: true,
          }}
        />
      </Box>
      <Box mr={3}>
        <Grid container item rowSpacing={2}>
          {vacationDates.map((field) => (
            <Grid item xs={6} key={field.id}>
              <Field
                label={field.label}
                name={field.name}
                variant="outlined"
                component={CustomizedDataPicker}
                inputFormat="dd/MM/yyyy"
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <VacationRequestValidateInfo data={validateVacationRequestData} />
          </Grid>
          <Grid item xs={6}>
            <Button
              sx={{ width: '90%' }}
              disabled={
                isSubmitting || !validateVacationRequestData?.isRequestValid
              }
              onClick={submitForm}
            >
              {t('save', { ns: 'buttons' })}
            </Button>
          </Grid>
          <br />
        </Grid>
        {isSubmitting && <LinearProgress />}
      </Box>
    </StyledForm>
  )
}

export default VacationRequestForm

import { useMutation, useQueryClient } from 'react-query'
import { useTranslation } from 'react-i18next'
import { AxiosResponse } from 'axios'
import { queryKeys } from 'react-query/constants'
import { apiRequest, APIRoutes } from 'apiServices'
import { NOTIFICATION, useNotifications } from 'components/common'

export interface IHolidaysCategory {
  id?: number
  name?: string
}
async function removeHolidayCategory(
  holidayCategoryId: number
): Promise<IHolidaysCategory[]> {
  const { data }: AxiosResponse = await apiRequest(
    APIRoutes.HOLIDAY_CATEGORY_DELETE,
    {},
    { id: holidayCategoryId }
  )
  return data
}

export const useHolidaysCategories = (holidayCategoryId?: number) => {
  const { t } = useTranslation('handleMessage')

  const { setNotification, handleCloseModal } = useNotifications()
  const queryClient = useQueryClient()

  async function addNewHolidayCategory(
    name: IHolidaysCategory
  ): Promise<IHolidaysCategory[]> {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.HOLIDAY_CATEGORY_CREATE,
      name
    )
    return data
  }

  async function updateCategory(
    name: IHolidaysCategory
  ): Promise<IHolidaysCategory[]> {
    const { data }: AxiosResponse = await apiRequest(
      APIRoutes.HOLIDAY_CATEGORY_UPDATE,
      name,
      { id: holidayCategoryId }
    )
    return data
  }

  const { mutate: addHolidayCategory } = useMutation(addNewHolidayCategory, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('category')} ${t('add')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.holidaysCategories])
    },
  })

  const { mutate: deleteCategory } = useMutation(removeHolidayCategory, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('category')} ${t('delete')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.holidaysCategories])
    },
  })

  const { mutate: editCategory } = useMutation(updateCategory, {
    onSuccess: () => {
      handleCloseModal()
      setNotification({
        message: `${t('category')} ${t('edit')}`,
        variant: NOTIFICATION.success,
      })
    },
    onSettled: () => {
      queryClient.invalidateQueries([queryKeys.holidaysCategories])
    },
  })

  return {
    addHolidayCategory,
    deleteCategory,
    editCategory,
  }
}
